body {
  font-family: Open Sans, sans-serif;
}

h1, h2, h3, .logo {
  font-family: Patrick Hand SC, serif;
  font-weight: normal;
}

h1 {
  margin-bottom: 1.5rem;
  font-size: 3.5rem;
  line-height: 3rem;
}

h2 {
  font-size: 2.5rem;
  line-height: 3rem;
  position: relative;
  top: -.3rem;
}

.logo {
  font-size: 2rem;
}
/*# sourceMappingURL=base.25c0af85.css.map */
